import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { SmallMobileFont } from "./typography"
import FlowMapImage from "./flowMapImage"

const Header = styled.h3`
  text-align: center;
  margin-bottom: 0.75rem;
`
const Content = styled.p`
  text-align: center;
  margin: 0;
  @media screen and (max-width: 768px) {
    margin: 0 0 1rem;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  max-width: 620px;
  margin: 150px auto 250px;
  padding: 46px;
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  @media screen and (max-width: 768px) {
    padding: 26px 12px;
    margin: 90px auto 120px;
  }
`

const WingImageWrapper = styled.div`
  position: absolute;
  z-index: -1;
  width: 26.5vw;
  left: ${(props) => (props.side === "left" ? "auto" : "60%")};
  right: ${(props) => (props.side === "left" ? "60%" : "auto")};
  transform: rotateY(${(props) => (props.side === "left" ? "0" : "180deg")})
    translateY(-25%);
  @media screen and (max-width: 900px) {
    width: 40vw;
  }
  @media screen and (max-width: 500px) {
    bottom: -25%;
  }
`

const WingedText = ({ title, text }) => {
  const data = useStaticQuery(
    graphql`
      query {
        file(name: { eq: "lastCtaWing" }) {
          publicURL
        }
      }
    `
  )
  return (
    <ContentWrapper>
      <WingImageWrapper side="left">
        <FlowMapImage
          imagePublicURL={data.file.publicURL}
          size={{ width: 384, height: 378 }}
        />
      </WingImageWrapper>
      <Header>
        <SmallMobileFont>{title}</SmallMobileFont>
      </Header>
      <div style={{ maxWidth: "90%" }}>
        <Content dangerouslySetInnerHTML={{ __html: text }}></Content>
      </div>
      <WingImageWrapper>
        <FlowMapImage
          flipMouse="x"
          imagePublicURL={data.file.publicURL}
          size={{ width: 384, height: 378 }}
        />
      </WingImageWrapper>
    </ContentWrapper>
  )
}

export default WingedText
